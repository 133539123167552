
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        







































.personality-duo {
  position: relative;
  overflow: hidden;
  padding-top: 120%;

  @include mq(s) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: col(1, 12);
    margin-top: $spacing * 1.5;
    padding-top: 0;

    &:nth-child(2n) {
      flex-direction: row-reverse;
      justify-content: space-between;
    }
  }

  @include mq(m) {
    &:nth-child(2n) {
      justify-content: center;
      gap: 0;
    }
  }
}

.personality-duo__picture {
  position: absolute;
  z-index: -1;
  top: 3%;
  left: 0;
  width: 100%;

  img {
    transform: scale(1.2);
  }

  @include mq(s) {
    position: static;
    width: col(5, 12);

    img {
      transform: none;
    }
  }
}

.personality-duo__content {
  padding-bottom: $spacing;

  @include mq(s) {
    width: col(5, 12);
    padding: $spacing * 2 0;
  }
}

.personality-duo__title {
  font-size: 6rem;
  font-style: normal;
  line-height: 1;

  ::v-deep em {
    color: $c-dark-grey;
  }
}

.personality-duo__text {
  margin: $spacing * 1.5 0;
  font-size: 1.5rem;
  line-height: 2.5rem;
}
