
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





























































































































.personality-hero {
  @extend %text-center;

  position: relative;
  overflow: hidden;

  &.before-onboard {
    opacity: 0;
  }
}

.personality-hero-wrapper {
  padding: $spacing * 3 0;

  @include mq(s) {
    position: relative;
    padding: $spacing * 8 0;
  }

  @include mq(xl) {
    padding: $spacing * 12 0;
  }
}

.personality-hero__title {
  margin: 0 auto 75%;

  ::v-deep em {
    color: $c-mustard;
  }

  @include mq(s) {
    margin-bottom: $spacing * 4;
  }
}

.personality-hero__text {
  max-width: 22em;
  margin: auto;
}

.personality-hero__picture {
  @include center-xy;

  z-index: -1;
  top: 60%;
  width: 100%;

  img {
    width: 100%;
  }

  @include mq(s) {
    display: none;
  }
}

.personality-hero__pictures {
  @include center-xy;

  z-index: -1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @include mq($until: s) {
    display: none;
  }
}

.personality-hero__pictures__inner {
  position: relative;
  left: -5%;
  width: 45%;

  & + & {
    left: 5%;
    width: 40%;
  }

  img {
    width: 100%;
  }
}
